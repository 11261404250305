





















import { Vue, Component } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import HeadingCard from "@/components/section/HeadingCard.vue";
import OAuthButton from '@/components/account/OAuthButton.vue';
import { SourceType } from '@arkive-ai/client-gateway-gql/dist/schema';
import { proxies } from '@/store';
import { oauth } from '@/plugins/oauth';
import { schema } from '@/gql';
import { gql } from 'apollo-boost';

@Component({
  components: {
    SectionContainer,
    HeadingCard,
    OAuthButton,
  },
})
export default class Login extends Vue {
  private user = proxies.User;
  private loading = false;

  private async login(sourceType: SourceType) {
    if (this.loading) return;
    this.loading = true;

    const results = await oauth.openExternalLoginPopup(sourceType);
    if (results) {
      await this.user.signInWithOauthToken(results.token);

      // Feature grant
      const feature: string | undefined = this.$route.query.feature as string;
      if (feature) {
        try {
          await this.$apollo.mutate<schema.Mutation>({
            mutation: gql`
              mutation featureGrant($input: MeFeatureGrantInput!) {
                meFeatureGrant(input: $input) {
                  id
                }
              }
            `,
            variables: {
              input: { feature }
            },
          });
        } catch (err) {
          console.error(err);
        }
      }

      const from: string | undefined = this.$route.query.from as string;
      if (from === undefined) {
        this.$router.push({ name: 'communities' });
      } else {
        this.$router.push(from);
      }
    } else {
      proxies.Snackbar.showWarning(`Login cancelled`);
    }

    this.loading = false;
  }


}
